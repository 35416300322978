import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyMenuGroup = lazy(
  () => import("./menu-group-8EKHb_ku.js").then((module) => ({
    default: module.MenuGroup
  }))
);
const LazyMenuGroupInternal = lazy(
  () => import("./menu-group-internal-DhkYrPK-.js").then((n) => n.e).then((module) => ({
    default: module.MenuGroupInternal
  }))
);
function MenuGroup({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyMenuGroup, { ...props });
}
const useMenuGroupContent = () => {
  return useMemo(
    () => ({
      menuGroup: {
        component: MenuGroup
      }
    }),
    []
  );
};
export {
  LazyMenuGroup as MenuGroup,
  LazyMenuGroupInternal as MenuGroupInternal,
  useMenuGroupContent
};
